import React, { Component } from 'react';

class DownloadMetaMaskButton extends Component {
  render() {
    const downloadButton = "https://i.imgur.com/vwS5ZJZ.png";
    return (
      <a href="https://metamask.io">
        <img className="downloadButton" src={downloadButton} alt="Download MetaMask"/>
      </a>
    )
  }
}

export default DownloadMetaMaskButton
