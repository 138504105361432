import React from "react";
import "./header.css";
import Particles from "react-particles-js";
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faGithub,
  faMedium,
  faReddit,
  faTelegram,
  faTiktok,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faCheckSquare,
  faDrumstickBite,
  faFaucet,
  faFile,
  faGrinSquint,
  faGrinTongue,
  faHandHoldingUsd,
  faHeadSideCough,
  faKissWinkHeart,
  faMinus,
  faPaperPlane,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";

// TRANSITION
import { Animated } from "react-animated-css";
import { useDencrypt } from "use-dencrypt-effect";
import HomeCarousel from "../../components/Carousel/HomeCarousel";
const values = ["NFTs into the DeFi space"];

const Header = () => {
  const { result, dencrypt } = useDencrypt();
  React.useEffect(() => {
    dencrypt(values[0]);
  }, []);

  return (
    <>
      <header>
        <Particles
          params={{
            particles: {
              number: {
                value: 160,
                density: {
                  enable: true,
                  value_area: 1500,
                },
              },
              line_linked: {
                enable: true,
                opacity: 0.01,
              },
              move: {
                direction: "top",
                speed: 0.5,
              },
              size: {
                value: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 1,
                },
              },
            },
            interactivity: {
              events: {
                onclick: {
                  enable: true,
                  mode: "push",
                },
              },
              modes: {
                push: {
                  particles_nb: 1,
                },
              },
            },
            retina_detect: true,
          }}
          className="part"
        />
        <Navbar />
        <div class="top-bar">
          <div class="container">
            <div class="d-flex justify-content-between">
              <div class="d-none d-lg-block w-100">
                <ul class="topnav">
                  <li class="text-white-50">
                    Alchemy in Blockchain - NFTs into the DeFi space
                  </li>
                </ul>
              </div>
              <div class="text-center text-lg-right w-100 icon-menu">
                <a
                  href="https://www.facebook.com/sphynxnetwork"
                  class="mr-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="facebook"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href="https://twitter.com/SphynxNetwork"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Twitter"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/sphynx.network/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://sphynx-network.medium.com/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Medium"
                >
                  <FontAwesomeIcon icon={faMedium} />
                </a>
                <a
                  href="https://www.reddit.com/u/SphynxNetwork/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Reddit"
                >
                  <FontAwesomeIcon icon={faReddit} />
                </a>
                <a
                  href="https://t.me/sphynxnetwork"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Telegram"
                >
                  <FontAwesomeIcon icon={faTelegram} />
                </a>
                <a
                  href="https://vm.tiktok.com/ZMe46Mv6a/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="TikTok"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
                <a
                  href="https://github.com/SphynxNetworkGitHub"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="GitHub"
                >
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                <a
                  href="https://www.linkedin.com/company/sphynx-network"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="LinkedIn"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="d-flex align-items-center header-row aos-init aos-animate flex-cont"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="0"
          >
            <div class="position-relative d-flex justify-content-around ">
              <div class="w-100">
                <img
                  src="https://i.imgur.com/nTDm0g5.png"
                  class="position-relative sc-jgrIVw DBTfN"
                />
                <h1 class="sc-hUpaWb blbXGf px-4">
                  SPHYNX
                  <br />
                  <span class="font-weight-normal darker">NETWORK</span>{" "}
                </h1>
              </div>
            </div>
            <div class="position-relative d-flex flex-column justify-content-around padding-left-60 py-4">
              <p class="text-light">We introduce</p>
              <h1 className="bignumbers no-phone">{result}</h1>
              <h1 className="bignumbers no-mac">NFTs into the DeFi space</h1>
              <p className="text-white fs-20px">
                Sphynx Network aims to improve the fundamental nature of DeFi,
                using alchemy in blockchain technology. This will comply with
                the next generation of yield farming and liquidity mining by
                implementing NFT staking. Focusing to deliver an NFT fractional
                ownership marketplace and a blockchain based play-to-earn NFT
                game.
              </p>
              <div className="d-flex justify-start w-100 mt-2 no-phone">
                <Animated
                  animationIn="pulse"
                  style={{ animationIterationCount: "infinite" }}
                >
                  <a
                    href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00"
                    target="_blank"
                    className="btn btn-secondary"
                  >
                    BUY $SPH
                  </a>
                </Animated>

                <a
                  href="/whitepaper.pdf"
                  target="_blank"
                  className="btn btn-white ml-8"
                >
                  Whitepaper
                </a>

                <a
                  href="https://bscscan.com/token/0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00"
                  target="_blank"
                  className="btn btn-primary ml-8"
                >
                  Contract
                </a>

                <a
                  href="https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/Sphynx_Network_SPH_Token_Smart_Contracts_Security_Audit_Report.pdf?alt=media&token=bdb1b0cf-e283-4b5b-beca-a3dfb3d88659"
                  target="_blank"
                  className="btn btn-success ml-8"
                >
                  <FontAwesomeIcon icon={faFile} className="mr-2" />
                  Contract Audit
                </a>
              </div>
              <div className="d-flex justify-start w-100 mt-2 flex-column no-mac">
                <Animated
                  animationIn="pulse"
                  style={{ animationIterationCount: "infinite" }}
                >
                  <a
                    href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00"
                    target="_blank"
                    className="w-100 btn btn-secondary"
                  >
                    BUY $SPH
                  </a>
                </Animated>

                <a
                  href="/whitepaper.pdf"
                  target="_blank"
                  className="btn btn-white my-2"
                >
                  Sphynx Network Whitepaper
                </a>

                <a
                  href="https://bscscan.com/token/0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00"
                  target="_blank"
                  className="btn btn-primary my-2"
                >
                  Sphynx Network Contract
                </a>

                <a
                  href="https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/Sphynx_Network_SPH_Token_Smart_Contracts_Security_Audit_Report.pdf?alt=media&token=bdb1b0cf-e283-4b5b-beca-a3dfb3d88659"
                  target="_blank"
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFile} className="mr-2" />
                  Contract Audit
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <HomeCarousel /> */}
      </header>
    </>
  );
};

export default Header;
