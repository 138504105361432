import React, { useEffect } from "react";
import "./abovefooter.css";
import TeamFigure from "../../components/TeamFigure/TeamFigure";
import Particles from "react-particles-js";
const AboveFooter = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/jquery-2.2.4.min.js?alt=media&token=ec721657-e028-448b-a0f8-4a3aa727bed3";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/main.js?alt=media&token=5e564e9a-1d48-4f37-8743-141ba4d0834d";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/jquery.mobile.custom.min.js?alt=media&token=03d75aaa-c58a-4ffb-9aba-0cd3cc371c72";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="position-relative">
      <Particles
        params={{
          particles: {
            number: {
              value: 160,
              density: {
                enable: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                speed: 4,
                size_min: 0.3,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              random: true,
              speed: 1,
              direction: "top",
              out_mode: "out",
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "bubble",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
            },
            modes: {
              bubble: {
                distance: 250,
                duration: 2,
                size: 0,
                opacity: 0,
              },
              repulse: {
                distance: 400,
                duration: 4,
              },
            },
          },
        }}
        className="part"
      />
      <div class="text-center">
        <div
          class="container"
          id="articles"
          style={{ position: "relative", zIndex: 1 }}
        >
          <div
            class="row justify-content-center medium-row align-items-lg-center"
            style={{ marginRight: "12px", marginLeft: "12px" }}
          >
            <div class="col-lg-3 col-md-4 col-sm-12">
              <TeamFigure
                p1="https://i.imgur.com/hkaTUJv.png"
                p2="https://i.imgur.com/goTOA5c.png"
                name="Madalin Muraretiu"
                position="CEO and Founder"
                linkedIn="https://www.linkedin.com/in/madalin-muraretiu-b8bb74105/"
                desc="Into Crypto Space Since 2016, Using Alchemy in Blockchain Tech to Deliver Disruptive Projects WW. Involved in the Growth and Development of Other Projects. "
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
              <TeamFigure
                p1="https://i.imgur.com/tbfH6S2.png"
                p2="https://i.imgur.com/K9fo4bU.png"
                name="Iulia Diaconescu"
                position="HR and Co-Founder"
                linkedIn="https://www.linkedin.com/in/iulia-diaconescu-318265b9/"
                desc="Supervising the Organization's Hiring Process of New Staff. As the Face of the Organization, Responsible for Managing  and Handling Communications."
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
              <TeamFigure
                p1="https://i.imgur.com/9AmneTB.png"
                p2="https://i.imgur.com/udCfEy3.png"
                name="Brijesh Kuvadyia"
                position="Backend Architect"
                linkedIn="https://www.linkedin.com/in/brijesh-kuvadiya/"
                desc="I'm a Solidity Developer and Blockchain Expert who is Developing the Backbone of Sphynx Network and Aiding in its Advancement by Adding New Features."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboveFooter;
