import React, { useEffect } from "react";
import "./team.css";
import TeamFigure from "../../components/TeamFigure/TeamFigure";
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
const Team = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/jquery-2.2.4.min.js?alt=media&token=ec721657-e028-448b-a0f8-4a3aa727bed3";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/main.js?alt=media&token=5e564e9a-1d48-4f37-8743-141ba4d0834d";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/jquery.mobile.custom.min.js?alt=media&token=03d75aaa-c58a-4ffb-9aba-0cd3cc371c72";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <div class="row">
          <div class="col-12">
            <h1 class="text-white mb-0">Sphynx Team</h1>
            <p class="text-white-50 fs-1rem">
              Meet some of the dream team behind the #SPHGANG. We are artists,
              we are creators, we are specialists, we are SPHYNX NETWORK.
            </p>
            <hr />
          </div>
        </div>
        <div class="row d-none">
          <div class="col-12">
            <div class="card text-white shadow border-0 card-bg-4">
              <div class="card-header text-muted ls-2 text-uppercase small bg-transparent">
                Announcement
              </div>
              <div class="card-body bg-transparent">
                <div class="d-flex align-items-center">
                  <div class="flex-fill">
                    <h1 class="mb-0 ls-2 fw-b text-white">BitMart</h1>
                    <p class="text-light">
                      The most trusted cryptocurrency trading platform which
                      provides real-time trading services including Bitcoin
                      (BTC), Ethereum (ETH) and Tether (USDT) trading. Safemoon
                      can easily be bought and sold on BitMart using USDT.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row justify-content-center medium-row align-items-lg-center"
          style={{ marginRight: "12px", marginLeft: "12px" }}
        >
          <div class="col-lg-3 col-md-4 col-sm-12">
            <TeamFigure
              p1="https://i.imgur.com/hkaTUJv.png"
              p2="https://i.imgur.com/goTOA5c.png"
              name="Madalin Muraretiu"
              position="CEO and Founder"
              linkedIn="https://www.linkedin.com/in/madalin-muraretiu-b8bb74105/"
              desc="Into Crypto Space Since 2016, Using Alchemy in Blockchain Tech to Deliver Disruptive Projects WW. Involved in the Growth and Development of Other Projects. "
            />
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12">
            <TeamFigure
              p1="https://i.imgur.com/tbfH6S2.png"
              p2="https://i.imgur.com/K9fo4bU.png"
              name="Iulia Diaconescu"
              position="HR and Co-Founder"
              linkedIn="https://www.linkedin.com/in/iulia-diaconescu-318265b9/"
              desc="Supervising the Organization's Hiring Process of New Staff. As the Face of the Organization, Responsible for Managing  and Handling Communications."
            />
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12">
            <TeamFigure
              p1="https://i.imgur.com/9AmneTB.png"
              p2="https://i.imgur.com/udCfEy3.png"
              name="Brijesh Kuvadyia"
              position="Backend Architect"
              linkedIn="https://www.linkedin.com/in/brijesh-kuvadiya/"
              desc="I'm a Solidity Developer and Blockchain Expert who is Developing the Backbone of Sphynx Network and Aiding in its Advancement by Adding New Features."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
