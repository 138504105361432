import React from 'react'
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import "./merch.css";
import { useDencrypt } from "use-dencrypt-effect";

const values = ["Coming Soon"];
const Merch = () => {
    const { result, dencrypt } = useDencrypt();
    React.useEffect(() => {
        
          dencrypt(values[0]);
    
    
      }, []);
    return (
      <>
      <Navbar />
      
      <div className="container my-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-white mb-0">Sphynx Merch</h1>
          <hr />
        </div>
      </div>
      <div className="container">
          <div className="m-height-620px merch-container d-flex flex-column flex-center">
              <img src="https://i.imgur.com/aVs1l6o.png" />
              <h1 className="text-white text-uppercase">{result}</h1>
            </div>
      </div>
      </div>
      </>
    )
}

export default Merch
