import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const Input = styled(TextField)`
  label {
    color: white !important;
  }

  input {
    color: white;
  }
`;
