import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Navitems from "../Navitems/Navitems";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../../Dialog/Dialog";
import { useWallet } from "../../../store/wallet";
import { useContracts } from "../../../store/contracts";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      width: "500px",
      minHeight: "450px",
    },
  },
}));

const MiddleAlign = styled.div`
  display: flex;
  align-items: center;
`;

const Navbarnou = () => {
  const classes = useStyles();

  const { isWeb3Enabled, address } = useWallet();
  const { sphynxToken } = useContracts();

  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(new BigNumber(0));

  useEffect(() => {
    (async () => {
      if (sphynxToken && sphynxToken.methods && address) {
        const _balance = await sphynxToken.methods.balanceOf(address).call();
        setBalance(new BigNumber(_balance));
      }
    })();
  }, [address, sphynxToken]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const displayBalance = useMemo(() => {
    return balance.multipliedBy(1e-18).toNumber().toLocaleString();
  }, [balance]);

  return (
    <>
      <div class="custom-nav text-center sticky-top">
        <div class="container">
          <div class="d-flex align-items-center custom-navbar justify-content-lg-between flex-column flex-lg-row">
            <div class="d-flex flex-column flex-lg-row text-center">
              <Navitems />
            </div>
            {isWeb3Enabled ? (
              <MiddleAlign>
                <img
                  alt="Sphynx Logo"
                  src="https://firebasestorage.googleapis.com/v0/b/sphynx-project-5ac24.appspot.com/o/sphynx-logo.png?alt=media&token=7e7757bc-74ba-4f2d-b0f5-311636c4d4f5"
                  className="w-30px"
                />
                {displayBalance}
              </MiddleAlign>
            ) : (
              <span
                className="con-b position-relative"
                onClick={() => handleClickOpen()}
              >
                Connect
              </span>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        className={classes.dialog}
        handleClose={handleClose}
      />
    </>
  );
};

export default Navbarnou;
