import React from "react";
import { Route, Switch } from "react-router-dom";

import light from "./utils/Themes/light";
import dark from "./utils/Themes/dark";
import GlobalStyles from "./utils/global";
import usePersistedState from "./utils/usePersistedState";
import { ThemeProvider } from "styled-components";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import { BrowserRouter } from "react-router-dom";

import { WalletProvider } from "./store/wallet";

// Routes
import Sidedrawer from "./components/Navigation/Sidedrawer/Sidedrawer";
import Home from "./containers/Home/Homenou";
import Footer from "./components/Footer/Footer";
import Team from "./containers/Team/Team";
import Tokenomics from "./containers/Tokenomics/Tokenomics";
import Merch from "./containers/Merch/Merch";
import NFT from "./containers/NFT/NFT";
import About from "./containers/About/About";
import Vaults from "./containers/Vaults/Vaults";
import Vaults2 from "./containers/Vaults/Vaults2";
import HowToBuy from "./containers/HowToBuy/HowToBuy";
import { ContractsProvider } from "./store/contracts";

function App() {
  const ethereum = window.ethereum;

  if (ethereum) {
    ethereum.on("accountsChanged", function (accounts) {
      console.log(accounts[0]);
    });
  }
  const [theme, setTheme] = usePersistedState("theme", dark);

  const toggleTheme = () => {
    setTheme(theme.title === "dark" ? light : dark);
  };

  return (
    <ThemeProvider theme={theme}>
      <WalletProvider>
        <ContractsProvider>
          <>
            <BrowserRouter>
              <ScrollToTop />
              <Switch>
                <Route exact path="/" component={Home}>
                  <Sidedrawer />
                  <Home />
                  <Footer />
                </Route>
                <Route exact path="/about" component={About}>
                  <Sidedrawer />
                  <About />
                  <Footer />
                </Route>
                <Route exact path="/team" component={Team}>
                  <Sidedrawer />
                  <Team />
                  <Footer />
                </Route>
                <Route exact path="/tokenomics" component={Tokenomics}>
                  <Sidedrawer />
                  <Tokenomics />
                  <Footer />
                </Route>
                <Route exact path="/merch" component={Merch}>
                  <Sidedrawer />
                  <Merch />
                  <Footer />
                </Route>
                <Route exact path="/nft" component={NFT}>
                  <Sidedrawer />
                  <NFT />
                  <Footer />
                </Route>
                <Route exact path="/vaults" component={Vaults}>
                  <Sidedrawer />
                  <Vaults />
                  <Footer />
                </Route>
                <Route exact path="/vaults2" component={Vaults2}>
                  <Sidedrawer />
                  <Vaults2 />
                  <Footer />
                </Route>
                <Route exact path="/how-to-buy" component={HowToBuy}>
                  <Sidedrawer />
                  <HowToBuy />
                  <Footer />
                </Route>
              </Switch>
            </BrowserRouter>
            <GlobalStyles />
          </>
        </ContractsProvider>
      </WalletProvider>
    </ThemeProvider>
  );
}

export default App;
