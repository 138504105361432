import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import styled from "styled-components";
import { ThemeContext } from "styled-components";

import { Link } from "react-router-dom";
import Logo from "../../Logo/Logo";
import Hamburger from "./Hamburger/Hamburger";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../../Dialog/Dialog";
import { useWallet } from "../../../store/wallet";
import BigNumber from "bignumber.js";
import { useContracts } from "../../../store/contracts";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      width: "500px",
      minHeight: "450px",
    },
  },
}));
const FixedWrapper = styled.div`
  position: fixed;
  background: hsl(211deg 37% 15%);
  padding: 0px;
  z-index: 1001 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: none;

  @media ${(props) => props.theme.mediaQueries.medium} {
    display: flex;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
`;

const Menu = styled.div`
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 60px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--color-bg-3);
  visibility: ${(props) => (props.opened ? "visible" : "hidden")};
  transform: translateY(${(props) => (props.opened ? "0%" : "-100%")});
  transition: all 0.1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  display: none;

  @media ${(props) => props.theme.mediaQueries.small} {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;
const SecondWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  margin-right: 20px;
`;

const ToggleProfile = styled.div`
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
`;

const MobileLink = styled(Link)`
  padding: 8px;
  border: 2px solid hsl(211deg 37% 15%);
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  width: 140px;

  &:hover {
    /* color: var(--color-background-15) !important; */
    border: 2px solid var(--color-background-13);
  }

  &.active {
    /* color: var(--color-background-15) !important; */
    border: 2px solid var(--color-background-13);
  }
`;

const MiddleAlign = styled.div`
  display: flex;
  align-items: center;
`;

const SideDrawer = () => {
  const classes = useStyles();

  const { isWeb3Enabled, address } = useWallet();
  const { sphynxToken } = useContracts();

  const [isOpened, setIsOpened] = useState(false);
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(new BigNumber(0));

  const handleClickOpen = useCallback(() => {
    setIsOpened(false);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    (async () => {
      if (sphynxToken && sphynxToken.methods && address) {
        const _balance = await sphynxToken.methods.balanceOf(address).call();
        setBalance(new BigNumber(_balance));
      }
    })();
  }, [address, sphynxToken]);

  const displayBalance = useMemo(() => {
    return balance.multipliedBy(1e-18).toNumber().toLocaleString();
  }, [balance]);

  return (
    <>
      <FixedWrapper>
        <Wrapper>
          <div className="d-flex flex-center">
            <Link to="/">
              <img
                alt="Sphynx Logo"
                src="https://firebasestorage.googleapis.com/v0/b/sphynx-project-5ac24.appspot.com/o/sphynx-logo.png?alt=media&token=7e7757bc-74ba-4f2d-b0f5-311636c4d4f5"
                className="w-40px"
              />
            </Link>
          </div>
          <div className="d-flex flex-center">
            <Hamburger
              opened={isOpened}
              clicked={() => setIsOpened(!isOpened)}
            />
          </div>
        </Wrapper>
      </FixedWrapper>
      <Menu opened={isOpened}>
        <div className="d-flex flex-column flex-center mt-16">
          <nav className="d-flex flex-column flex-center text-center mt-8">
            {isWeb3Enabled ? (
              <MiddleAlign>
                <img
                  alt="Sphynx Logo"
                  src="https://firebasestorage.googleapis.com/v0/b/sphynx-project-5ac24.appspot.com/o/sphynx-logo.png?alt=media&token=7e7757bc-74ba-4f2d-b0f5-311636c4d4f5"
                  className="w-30px"
                />
                {displayBalance}
              </MiddleAlign>
            ) : (
              <span
                className="con-b position-relative"
                onClick={() => handleClickOpen()}
              >
                Connect
              </span>
            )}
            <MobileLink onClick={() => setIsOpened(false)} exact="true" to="/">
              Home
            </MobileLink>
            <MobileLink
              onClick={() => setIsOpened(false)}
              to="/about"
              exact="true"
            >
              About
            </MobileLink>
            <MobileLink
              onClick={() => setIsOpened(false)}
              to="/tokenomics"
              exact="true"
            >
              Tokenomics
            </MobileLink>
            <MobileLink
              onClick={() => setIsOpened(false)}
              to="/how-to-buy"
              exact="true"
            >
              How to Buy
            </MobileLink>
            <MobileLink
              onClick={() => setIsOpened(false)}
              to="/team"
              exact="true"
            >
              Team
            </MobileLink>
            <MobileLink
              onClick={() => setIsOpened(false)}
              to="vaults"
              to="/vaults"
              exact="true"
            >
              Vaults
            </MobileLink>
            <MobileLink
              onClick={() => setIsOpened(false)}
              to="nft"
              to="/nft"
              exact="true"
            >
              NFTs
            </MobileLink>
            <MobileLink
              onClick={() => setIsOpened(false)}
              to="/merch"
              exact="true"
            >
              Merch
            </MobileLink>
          </nav>
        </div>
        <Dialog
          open={open}
          className={classes.dialog}
          handleClose={handleClose}
        />
      </Menu>
    </>
  );
};

export default SideDrawer;
