import React, { useState, useEffect } from "react";
// Animate on scroll
import ScrollAnimation from "@articulate/react-animate-on-scroll";
import "./functions.css";
const Functions = () => {
  const [tab, setTab] = useState(0);
  React.useEffect(() => {
    let i = 0;

    const action = setInterval(() => {
      setTab(i);

      i = i === 3 - 1 ? 0 : i + 1;
    }, 5000);

    return () => clearInterval(action);
  }, []);
  return (
    <div className="container mt-16">
      <div class="row medium-row align-items-lg-center">
        <div class="col-12 col-lg-6">
          <div class="nav flex-column nav-pills" id="about-tab">
            <a
              onClick={() => setTab(0)}
              class={`nav-link mb-3 cursor-pointer ${
                tab === 0 ? "active" : ""
              }`}
              id="team-tab-btn"
              data-toggle="pill"
            >
              <h2 className="text-gray">Sphynx Rewards</h2>
              <p class="mb-0 text-light">
                From LP yield farming rewards - 5000 SPH each 3 seconds to NFT
                staking, unlocking multiple rewards from different platforms
                simultaneously.
              </p>
            </a>
            <a
              onClick={() => setTab(1)}
              class={`nav-link mb-3 cursor-pointer ${
                tab === 1 ? "active" : ""
              }`}
              id="pool-tab-btn"
              data-toggle="pill"
            >
              <h2 className="text-gray">Sphynx Liquidity</h2>
              <p class="mb-0 text-light">
                Automatic LP function by contract design, trades within the
                network will contribute to generating liquidity inside
                Pancakeswap pool.
              </p>
            </a>
            <a
              onClick={() => setTab(2)}
              class={`nav-link mb-0 cursor-pointer ${
                tab === 2 ? "active" : ""
              }`}
              id="reward-tab-btn"
              data-toggle="pill"
            >
              <h2 className="text-gray">Sphynx Distribution</h2>
              <p class="mb-0 text-light">
                Holding will now earn you passive rewards in SPH, lifetime! As
                long as trades are made within the network your equity in SPH
                will constantly grow.
              </p>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="tab-content text-center" id="v-pills-tabContent">
            <div
              class={`tab-pane fade ${tab === 0 ? "show active" : ""}`}
              id="team-tab"
            >
              <img src="https://i.imgur.com/CqWfomo.png" class="img-fluid" />
            </div>
            <div
              class={`tab-pane fade ${tab === 1 ? "show active" : ""}`}
              id="pool-tab"
            >
              <img src="https://i.imgur.com/93mJ2Ih.png" class="img-fluid" />
            </div>
            <div
              class={`tab-pane fade ${tab === 2 ? "show active" : ""}`}
              id="reward-tab"
            >
              <img src="https://i.imgur.com/yJ2R8eO.png" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Functions;
