import swal from "sweetalert";
import "./style.css";

export const SweetAlert = (title, text, button) => {
  swal({
    title: `${title}`,
    text: `${text}`,
    button: `${button}`,
  });
};

export const SweetAlertNoButtons = (title, text) => {
  swal({
    title: `${title}`,
    text: `${text}`,
    button: false,
  });
};

export const SweetAlertIcons = (title, text, icon, button) => {
  swal({
    title: `${title}`,
    text: `${text}`,
    icon: `${icon}`,
    button: `${button}`,
  });
};

export const SweetAlertIconsNoButton = (title, text, icon) => {
  swal({
    title: `${title}`,
    text: `${text}`,
    icon: `${icon}`,
    timer: 2000,
    buttons: false,
  });
};

export const SweetAlertTest = (c, text) => {
  swal({
    className: `${c}`,
    buttons: false,
    text: `${text}`,
    timer: 2000,
  });
};

// ICONS
// "warning"
// "error"
// "success"
// "info"
