import React from "react";
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import "./About.css";
import { useDencrypt } from "use-dencrypt-effect";
// Animate on scroll
import ScrollAnimation from "@articulate/react-animate-on-scroll";
import Particles from "react-particles-js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faMinus } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAccordionSummary-root": {
      background: "rgb(21, 24, 29);",
      width: "100%",
      color: "white",
    },
    "& .MuiIconButton-root": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  },
  heading: {
    fontSize: 16,
    width: "100%",
  },
  p: {
    fontSize: 16,
  },
}));
const About = () => {
  const classes = useStyles();
  const values = ["From the BEP20 standard to the BEP721 standard (NFT)"];
  const { result, dencrypt } = useDencrypt();
  React.useEffect(() => {
    dencrypt(values[0]);
  }, []);
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <div class="row">
          <div class="col-12">
            <h1 class="text-white mb-0">About Sphynx Network</h1>
            <hr />
          </div>
        </div>
        <div className="white py-4">
          <div className="mw1100 h-100 w-100 d-flex flex-center flex-column py-4">
            <div className="d-flex  tokenomics-data">
              <div className="d-flex flex-column">
                <h1 className="text-orange mb-6">{result}</h1>
                <p className="font-size-h4 mb-4">
                  BEP-20 tokens now dominate the DeFi sector on the BSC network,
                  being fungible tokens, they are identical to one another.While
                  these tokens are necessary and will not disappear anytime
                  soon, there are many additional token standards that are
                  rarely discussed, such as BEP-721 tokens, which are
                  non-fungible, meaning that each token is unique due to the
                  token id assigned to it at the time of minting.
                </p>
                <p className="font-size-h4 mb-4">
                  BEP-721 are mostly utilized as collectibles, but the Sphynx
                  Network will reimagine their use by introducing them into the
                  DeFi space.
                </p>
              </div>

              <div
                className="nft-card d-flex flex-column flex-center position-relative mx-4"
                style={{
                  border: "12px solid orange",
                  borderTopRightRadius: 8,
                  borderBottomLeftRadius: 8,
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/IMG_6305.gif?alt=media&token=8fd27c83-0cbf-4d05-8e92-e0b152eae70c"
                  width="260"
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100 mt-4 ">
              <div className="nft-about d-flex flex-column w-100 flex-center">
                <div className="d-flex flex-column">
                  <h2 className="text-orange">What is SPH?</h2>
                  <p className="font-size-h4">
                    Sphynx Network is a decentralized token built on Solidity
                    and operating on the Binance Network. The project is audited
                    and secured by EtherAuthority, a notable and esteemed audit
                    company within the crypto space.
                  </p>
                </div>
                <div className="d-flex flex-column my-4">
                  <h2 className="text-orange">
                    What is the project description?
                  </h2>
                  <div className="row">
                    <div className="col-md-12 text-center d-flex align-items-center flex-cont">
                      <img src="https://i.imgur.com/6jFLXub.png" width="240" />
                      <p className="font-size-h4">
                        Sphynx Network is using alchemy in blockchain to develop
                        and comply with the next generation of yield farming and
                        liquidity mining by introducing the full potential of
                        non-fungible tokens (NFTs) to the decentralized finance
                        industry (DeFi). Therefore, Sphynx Network developed the
                        conventional DeFi option, which enables LP staking in a
                        number of pools and earns SPH for it, the platform's
                        governance token.
                      </p>
                    </div>
                    <div className="col-md-12 text-center d-flex align-items-center flex-cont">
                      <img src="https://i.imgur.com/53APjUp.png" width="240" />
                      <p className="font-size-h4">
                        Alchemy in Blockchain it is utilized to mint and stake a
                        single NFT, once only, in order to simultaneously unlock
                        a series of rewards from various platforms! NFT staking
                        is the second product of Sphynx Network's development
                        plan.{" "}
                      </p>
                    </div>
                    <div className="col-md-12 text-center d-flex align-items-center flex-cont">
                      <img src="https://i.imgur.com/gHokqwZ.png" width="240" />
                      <div>
                        <p className="font-size-h4">
                          When it comes to the NFT marketplace, it is divided
                          into several sections and performs DeFi-specific
                          functions. Users can flip or hold their NFTs, and
                          their NFTs value will increase over time due to the
                          smart contract design algorithm that will distribute a
                          certain percentage of every market place transaction
                          to the value of the NFTs users hold.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex align-items-center">
                      <div>
                        <p className="font-size-h4">
                          The second segment of the market is occupied by
                          fractionalized ownership of NFTs. Holders of Sphynx
                          Network who own an NFT may fractionalize it, deposit
                          them in a vault, and get 100% in fractions, increasing
                          the liquidity of an illiquid product as an NFT.
                        </p>
                        <p className="font-size-h4 mt-2">
                          Another area of the marketplace, where players may
                          trade various assets and use them to customize their
                          characters in their teams, is where players can buy,
                          sell, and couple various breeds of "sphynxies," which
                          are the characters in the game we are developing as
                          the project's fourth product.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 text-center d-flex align-items-center flex-cont">
                      <img src="https://i.imgur.com/fkUHKFL.png" width="240" />
                      <p className="font-size-h4">
                        The ultimate goal of Sphynx Network's product
                        development team is to deliver an NFT play to earn game,
                        where players are actually getting rewards and making
                        money out of it, on a daily basis.{" "}
                      </p>
                    </div>

                    <div className="col-md-12 d-flex align-items-center">
                      <div>
                        <p className="font-size-h4">
                          Characters are divided based on several elements such
                          as earth, water, fire, alchemy, shadow, and psychic,
                          and will have a variety of characteristics such as
                          health, defense, attack, agility, rarity, magic level
                          and more.{" "}
                        </p>
                        <p className="font-size-h4 mt-2">
                          The game features two battle modes: adventure, in
                          which players earn in-game rewards and mystery boxes
                          containing BNB, SPH, NFTs, products from the Sphynx
                          shop, and other freebies as they level up, and combat
                          arena, in which players of the same level form a team
                          and compete against other users for BNB, SPH, and NFTs
                          rewards
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column flex-center py-8 no-phone"
        style={{ background: "#15181d" }}
      >
        <div className="mw1100 d-flex flex-center flex-column section-title">
          <h2>FAQ</h2>
          <p className="text-gray">Frequently Asked Question - Sphynx</p>
        </div>
      </div>

      <div className="container my-5  no-phone">
        <div className="d-flex flex-column">
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What is Sphynx Network?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Sphynx Network's SPH is a decentralized token built on Solidity
                and operating on the Binance Network. The project is audited and
                secured by EtherAuthority, a notable and esteemed audit company
                within the crypto space.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What is the mission?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Sphynx Network aims to improve the fundamental nature of DeFi,
                using alchemy in blockchain technology. This will comply with
                the next generation of yield farming and liquidity mining by
                implementing NFT staking. Focusing to deliver an NFT fractional
                ownership marketplace and a blockchain based play-to-earn NFT
                game.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What are the use cases of SPH ?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />{" "}
                Staking Vaults - Stake your LPs and instantly get 5000 SPH every
                3 seconds!
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />{" "}
                NFT staking vaults - Become an Alchemist now, with a 5x to 10x
                reward multiplier
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />{" "}
                NFT market place - Fractional ownership. Collect them all or
                become a Sphynx breeder, sell them or use them to gain rewards!
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />{" "}
                Play to Earn Blockchain Based Game get in-game rewards in
                adventure mode or fight other users in combat arena to for SPH
                and BNB rewards.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Where is listed?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                Pancakeswap -{" "}
                <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00">
                  https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00
                </a>{" "}
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                1inch -{" "}
                <a href="https://app.1inch.io/#/56/swap/BNB/SPH">
                  https://app.1inch.io/#/56/swap/BNB/SPH
                </a>{" "}
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                Latoken -{" "}
                <a href="https://latoken.com/exchange/SPHN_USDT">
                  https://latoken.com/exchange/SPHN_USDT
                </a>{" "}
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                Xt.com -{" "}
                <a href="https://www.xt.com/tradePro/sph_usdt ">
                  https://www.xt.com/tradePro/sph_usdt{" "}
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Are you listed on CoinMarketCap and CoinGecko?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                Coinmarketcap:{" "}
                <a href="https://coinmarketcap.com/currencies/sphynx-network/">
                  https://coinmarketcap.com/currencies/sphynx-network/
                </a>
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                Coingecko:{" "}
                <a href="https://www.coingecko.com/en/coins/sphynx-network">
                  https://www.coingecko.com/en/coins/sphynx-network
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Can you stake $SPH using Trust Wallet / MetaMask ?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Yes.{" "}
                <a href="https://www.sphynx.network/vaults">
                  https://www.sphynx.network/vaults
                </a>{" "}
                , once connected.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                How to stake SPH ?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                <b>Guidelines to farm $SPH</b>
                <br />
                1. Like merging two chemicals in equal amounts, 50%/50%, SPH and
                BNB. If 500$ worth of SPH then 500$ worth of BNB. The outcome of
                their mixture is referred to as cake LP, cause of pancakeswap
                and it holds the value of your combined assets.
                <br />
                Add liquidity here:
                <a href="https://exchange.pancakeswap.finance/#/add/BNB/0x8bAc6b4AF65C8c1967a0FBc27cd37FD6059daa00">
                  https://exchange.pancakeswap.finance/#/add/BNB/0x8bAc6b4AF65C8c1967a0FBc27cd37FD6059daa00
                </a>{" "}
                <br />
                <br />
                2. The LPs are now in your wallet, head to vaults:{" "}
                <a href="https://www.sphynx.network/vaults">
                  https://www.sphynx.network/vaults
                </a>{" "}
                find BNB/SPH vault, after connecting your wallet, click "stake
                BNB-SPH", now you should be staking, drag the orange line right
                till the end of it and you should now be seeing how many LP
                tokens you own, now click deposit. Congratulations! enjoy 5000
                SPH each 3 seconds! <br />
                <br />
                *SPH can be bought:{" "}
                <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00">
                  https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What are the rewards for staking?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                5000 SPH every 3 seconds split among delegators based on their
                number and LP value.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Team funds locked?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Funds Locked Dxsale here :{" "}
                <a href="https://dxsale.app/app/pages/dxlockview?id=0&add=0x257154F13d4A9ea507E3bc17cD5281cFeE8E0a22&type=tokenlock&chain=BSC">
                  https://dxsale.app/app/pages/dxlockview?id=0&add=0x257154F13d4A9ea507E3bc17cD5281cFeE8E0a22&type=tokenlock&chain=BSC
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Is SPH deflationary?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Initial 10 % burned, due to reflection, dead address is also a
                holder and absorbs part of the reflection, yes, SPH is a
                deflationary token.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What about slippage?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Slippage it's 12% <br />
                10% transaction fee split:
                <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                5% reflection - will be redistributed to existing holders based
                on their number and proportional to their equities in $SPH, a
                form of staking rewards, without actually staking but just for
                holding. <br />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="mr-2 text-orange"
                />
                5% auto LP - will be redirected towards the liquidity pool to
                sustain the price, a defense against dumps.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Is the contract renounced?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Contract ownership not renounced and will never happen, as SPH
                is far from its final version. Sphynx Network delivers 4 main
                different uses case. There is a contract audit that answers to
                concerns.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Have you been audited?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Contract audit here:{" "}
                <a href="https://github.com/SphynxNetworkGitHub/sphynx-contract/blob/main/audit-report/Sphynx_Network_SPH_Token_Smart_Contracts_Security_Audit_Report.pdf ">
                  https://github.com/SphynxNetworkGitHub/sphynx-contract/blob/main/audit-report/Sphynx_Network_SPH_Token_Smart_Contracts_Security_Audit_Report.pdf{" "}
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Is the liquidity locked?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Liquidity locked DxSale here :{" "}
                <a href="https://dxsale.app/app/pages/dxlockview?id=2470&add=0&type=lpdefi&chain=BSC">
                  https://dxsale.app/app/pages/dxlockview?id=2470&add=0&type=lpdefi&chain=BSC
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Are the devs doxxed?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Please check Team page here{" "}
                <a href="https://www.sphynx.network/team">
                  https://www.sphynx.network/team
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What are the tokenomics?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                Please check Tokenomics page here{" "}
                <a href="https://www.sphynx.network/tokenomics">
                  https://www.sphynx.network/tokenomics
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Sphynx Network International groups?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div class="card-body p-0 font-size-h6">
                <a href="https://t.me/sphynxnetwork">
                  {" "}
                  https://t.me/sphynxnetwork - General{" "}
                </a>{" "}
                <br />
                <a href="https://t.me/sphynxnetwork_TR">
                  {" "}
                  https://t.me/sphynxnetwork_TR - Turkey{" "}
                </a>{" "}
                <br />
                <a href="https://t.me/sphynxnetwork_PT">
                  {" "}
                  https://t.me/sphynxnetwork_PT - Portugal{" "}
                </a>{" "}
                <br />
                <a href="https://t.me/sphynxnetwork_SP">
                  {" "}
                  https://t.me/sphynxnetwork_SP - Spain{" "}
                </a>{" "}
                <br />
                <a href="https://t.me/sphynxnetwork_RO">
                  {" "}
                  https://t.me/sphynxnetwork_RO - Romania{" "}
                </a>{" "}
                <br />
                <a href="https://t.me/sphynxnetwork_CN">
                  {" "}
                  https://t.me/sphynxnetwork_CN - China{" "}
                </a>{" "}
                <br />
                <a href="https://t.me/sphynxnetwork_IN">
                  {" "}
                  https://t.me/sphynxnetwork_IN - India{" "}
                </a>{" "}
                <br />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-center py-8"
        style={{ background: "#15181d" }}
      >
        <div className="mw1100 d-flex flex-center flex-column section-title">
          <h2>Journey</h2>
          <p className="text-gray">Long story short</p>
        </div>
      </div>
      <div className=" d-flex flex-column position-relative">
        <Particles
          params={{
            particles: {
              number: {
                value: 160,
                density: {
                  enable: false,
                },
              },
              size: {
                value: 3,
                random: true,
                anim: {
                  speed: 4,
                  size_min: 0.3,
                },
              },
              line_linked: {
                enable: false,
              },
              move: {
                random: true,
                speed: 1,
                direction: "top",
                out_mode: "out",
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "bubble",
                },
                onclick: {
                  enable: true,
                  mode: "repulse",
                },
              },
              modes: {
                bubble: {
                  distance: 250,
                  duration: 2,
                  size: 0,
                  opacity: 0,
                },
                repulse: {
                  distance: 400,
                  duration: 4,
                },
              },
            },
          }}
          className="part"
        />
        <div className="container my-5">
          <p className="font-size-h4 mb-4">
            <FontAwesomeIcon icon={faDotCircle} className="mr-2 text-orange" />
            Staking Vaults - Stake your LPs and instantly get 5000 SPH every 3
            seconds!{" "}
          </p>
          <p className="font-size-h4 mb-4">
            <FontAwesomeIcon icon={faDotCircle} className="mr-2 text-orange" />
            NFT staking vaults - Become an Alchemist now! Enjoy a 5x to 10x
            reward multiplier in rewards.
          </p>
          <p className="font-size-h4 mb-4">
            <FontAwesomeIcon icon={faDotCircle} className="mr-2 text-orange" />
            NFT market place - Fractional ownership. Collect them all or
            fractionalize your NFT and enjoy some liquidity! Become a Sphynx
            breeder now! Sell them or use them to gain in game rewards!
          </p>
          <p className="font-size-h4 mb-4">
            <FontAwesomeIcon icon={faDotCircle} className="mr-2 text-orange" />
            Play to Earn Blockchain Based Game Get in-game rewards in adventure
            mode or fight other users in combat arena to for SPH and BNB
            rewards.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
