import React from "react";
import { Link } from "react-scroll";
import "./footer.css";
// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faGithub,
  faMedium,
  faReddit,
  faTelegram,
  faTiktok,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <div class="footer" id="footer">
      <div class="container">
        <>
          <div class="row py-4 align-items-center">
            <div class="col-12 col-lg-6">
              <h1 class="text-white">Sphynx Network</h1>
              <p className="fs-1rem">
                Sphynx Network is a disruptive project targeting to use the full
                potential of NFTs into the DeFi space
              </p>
            </div>
            <div class="col-12 col-lg-6">
              <p class="fs-1rem mt-4">
                If you have any questions regarding Sphynx Network, feel free to
                contact us using the email address or social media.
              </p>
              <h4 class="text-white">
                Email address :{" "}
                <a className="text-orange" href="mailto:contact@sphynx.network">
                  contact@sphynx.network
                </a>
              </h4>
              <p class="fs-1rem ">
                For a faster answer, live interaction with the team or just to
                talk about Sphynx Network, you can use our social media links
              </p>
            </div>
          </div>
          <hr />
          <div class="row align-items-center">
            <div class="col-12 col-lg-6">
              <h3 class="mb-0 text-white">
                Copyright © 2021 SphynxNetwork. | All Rights Reserved
              </h3>
            </div>
            <div class="col-12 col-lg-6">
              <div class="text-center text-lg-right w-100 icon-menu">
                <a
                  href="https://www.facebook.com/sphynxnetwork"
                  class="mr-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="facebook"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href="https://twitter.com/SphynxNetwork"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Twitter"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/sphynx.network/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://sphynx-network.medium.com/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Medium"
                >
                  <FontAwesomeIcon icon={faMedium} />
                </a>
                <a
                  href="https://www.reddit.com/u/SphynxNetwork/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Reddit"
                >
                  <FontAwesomeIcon icon={faReddit} />
                </a>
                <a
                  href="https://t.me/sphynxnetwork"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Telegram"
                >
                  <FontAwesomeIcon icon={faTelegram} />
                </a>
                <a
                  href="https://vm.tiktok.com/ZMe46Mv6a/"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="TikTok"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
                <a
                  href="https://github.com/SphynxNetworkGitHub"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="GitHub"
                >
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                <a
                  href="https://www.linkedin.com/company/sphynx-network"
                  class="mx-2 text-gray"
                  target="_blank"
                  data-toggle="tooltip"
                  title="LinkedIn"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Footer;
