import React from "react";

// MUI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useWallet } from "../../store/wallet";
import { useCallback } from "react";

import walletConnectLogo from "../../assets/walletconnect.svg";

const useStyles = makeStyles((theme) => ({
  parent: {
    zIndex: "9999 !important",
    "& .MuiPaper-root": {
      backgroundColor: "var(--color-bg-3)",
      color: "var(--color-blackAndWhiteR)",
      "@media (min-width: 768px)": {
        minWidth: "500px",
      },
      "@media (max-width: 768px)": {
        minWidth: "-webkit-fill-available",
      },
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "4px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButtonBase-root": {
      position: "relative !important",
      top: "inherit !important",
      right: "inherit !important",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className="font-size-lg">{children}</div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between !important",
    padding: "8px 16px !important",
  },
}))(MuiDialogActions);

const DialogDetails = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: "column !important",
    justifyContent: "flex-start !important",
    padding: "8px 16px !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
}))(MuiDialogActions);

const DialogChat = ({ handleClose, open }) => {
  const classes = useStyles();
  const wallet = useWallet();

  const handleConnect = useCallback(
    async (api) => {
      try {
        await wallet.enableWeb3(api);
        handleClose();
      } catch {}
    },
    [handleClose, wallet]
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.parent}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Select a wallet provider
      </DialogTitle>
      <DialogContent dividers>
        <div className="d-flex flex-column">
          <div
            className="con-b d-flex align-items-center"
            onClick={() => handleConnect("ethereum")}
          >
            <img
              alt="MetaMask Icon"
              className="w-40px"
              src="https://i.imgur.com/7RMUtlo.png"
            />
            <span className="fs-16px ml-2">
              MetaMask
              <small style={{ marginLeft: "5px" }}>(or TrustWallet, etc)</small>
            </span>
          </div>
          <div
            className="con-b mt-2 d-flex align-items-center"
            onClick={() => handleConnect("walletconnect")}
          >
            <img
              alt="WalletConnect Icon"
              className="w-40px"
              src={walletConnectLogo}
            />
            <span className="fs-16px ml-2">WalletConnect</span>
          </div>
          <div
            className="con-b mt-2 d-flex align-items-center"
            onClick={() => handleConnect("BinanceChain")}
          >
            <img
              alt="Binance Chain Wallet Icon"
              className="w-40px"
              src="https://i.imgur.com/gsmuaAe.png"
            />
            <span className="fs-16px ml-2">Binance Chain Wallet</span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogChat;
