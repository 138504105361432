import React from 'react'
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import "./nft.css";
import { useDencrypt } from "use-dencrypt-effect";

import Particles from 'react-particles-js';
const values = ["Coming Soon"];
const NFT = () => {
    const { result, dencrypt } = useDencrypt();
    React.useEffect(() => {
        
          dencrypt(values[0]);
    
    
      }, []);
    return (
      <>
          
      <Navbar />
      <div className="position-relative">
      <Particles
    params={{
	    "particles": {
	        "number": {
	            "value": 8,
	            "density": {
	                "enable": true,
	                "value_area": 800
	            }
	        },
	        "line_linked": {
	            "enable": false
	        },
	        "move": {
	            "speed": 1,
	            "out_mode": "out"
	        },
	        "shape": {
	            "type": [
	                "image",
	                "circle"
	            ],
	            "image": [
	                {
	                    "src": "https://firebasestorage.googleapis.com/v0/b/sphynx-project-5ac24.appspot.com/o/sphynx-logo.png?alt=media&token=7e7757bc-74ba-4f2d-b0f5-311636c4d4f5",
	                    "height": 20,
	                    "width": 23
	                },
	                {
	                    "src": "https://firebasestorage.googleapis.com/v0/b/sphynx-project-5ac24.appspot.com/o/sphynx-logo.png?alt=media&token=7e7757bc-74ba-4f2d-b0f5-311636c4d4f5",
	                    "height": 20,
	                    "width": 20
	                },
	                {
	                    "src": "https://firebasestorage.googleapis.com/v0/b/sphynx-project-5ac24.appspot.com/o/sphynx-logo.png?alt=media&token=7e7757bc-74ba-4f2d-b0f5-311636c4d4f5",
	                    "height": 20,
	                    "width": 20
	                }
	            ]
	        },
	        "color": {
	            "value": "#CCC"
	        },
	        "size": {
	            "value": 30,
	            "random": false,
	            "anim": {
	                "enable": true,
	                "speed": 4,
	                "size_min": 10,
	                "sync": false
	            }
	        }
	    },
	    "retina_detect": false
	}} className="part" />
      <div className="container my-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-white mb-0">Sphynx NFT</h1>
          <hr />
        </div>
      </div>
      <div className="container">
          <div className="m-height-620px merch-container d-flex flex-column flex-center">
                <img src="https://i.imgur.com/aVs1l6o.png" />
                <h1 className="text-white text-uppercase">{result}</h1>
        </div>
      </div>
      </div>
      </div>
      </>
    )
}

export default NFT
