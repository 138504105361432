import React from 'react'
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import { PieChart } from 'react-minimal-pie-chart';
import "./tokenomics.css";

// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBezierCurve, faLock } from "@fortawesome/free-solid-svg-icons";

const Tokenomics = () => {
    return (
      <>
      <Navbar />
      
      <div className="container my-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-white mb-0">Allocation</h1>
          <p class="text-white-50 fs-1rem">The issued tokens will be distributed as follows</p>
          <hr />
        </div>
      </div>
      
      <div className="graph-tokens d-flex w-100 flex-center my-8">
                    
                    <div className="w-100 d-flex flex-center flex-toke">
                            <div className="d-flex w-100">
                            <PieChart className="max-h-320px w-100"
                            data={[
                                { title: 'Project development', value: 13.7, color: '#5f0624' },
                                { title: 'Rewards', value: 1.3, color: '#805020' },
                                { title: 'Team and Advisors', value: 15, color: '#504538' },
                                { title: 'PreSale (Community Members)', value: 54, color: '#038272' },
                                { title: 'Marketing', value: 6, color: '#B6A999' },
                                { title: 'Initial Burn', value: 10, color: '#062b48' },
                            ]}
                            />
                            </div>
                        <div className="w-100 d-flex justify-content-around flex-column">
                            <div className="d-flex w-100">
                            <div className="bgtd4 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                                <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">54%</span>
                                <span className="text-center">PreSale (Community Members) </span>
                            </div>    
                            <div className="bgtd5 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                                <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">13.7%</span>
                                <span className="text-center">Project development</span>
                            </div>     
                            <div className="bgtd9 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                                <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">1.3%</span>
                                <span className="text-center">Rewards</span>
                            </div>  
                            </div>
                            <div className="d-flex w-100">
                                   
                            <div className="bgtd7 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                                <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">15%</span>
                                <span className="text-center">Team and Advisors</span>
                            </div>     
                            <div className="bgtd6 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                                <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">6%</span>
                                <span className="text-center">Marketing</span>
                            </div>     
                            <div className="bgtd8 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                                <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">10%</span>
                                <span className="text-center">Initial Burn</span>
                            </div>   
                            </div>
                            <div className="d-flex w-100">
   
                            <div className="card card-bg-3 p-4 m-2 d-flex flex-center flex-column w-100">
                                <span className="text-orange font-size-bigger text-center"><FontAwesomeIcon icon={faLock} /></span>
                                <span className="text-center">Liquidity Locked</span>
                            <a className="text-orange text-uppercase font-size-sm" href="https://dxsale.app/app/pages/dxlockview?id=2470&add=0&type=lpdefi&chain=BSC" target="_blank">check here</a>
                            </div>  
                            <div className="card card-bg-3 p-4 m-2 d-flex flex-center flex-column w-100">
                                <span className="text-orange font-size-bigger text-center"><FontAwesomeIcon icon={faBezierCurve} /></span>
                                
                            <span className="text-center">Team and Advisors Funds Vested</span>
                            <a className="text-orange text-uppercase font-size-sm" href="https://dxsale.app/app/pages/dxlockview?id=0&add=0x257154F13d4A9ea507E3bc17cD5281cFeE8E0a22&type=tokenlock&chain=BSC" target="_blank">check here</a>
                            </div>  
                            </div>
                        </div>
                        </div>
                    </div>
                    
      <div class="row">
        <div class="col-12">
          <h1 class="text-white mb-0">Distribution</h1>
          <p class="text-white-50 fs-1rem">Out of each transaction made within the Sphynx Network: <br />
<span style={{color: "#f7b126" }}>5% reflection</span> - will be redistributed to existing holders based on their number and equities in $SPH, a form of staking rewards, without actually staking but just for holding <br />
<span style={{color: "#f7b126" }}>5% auto LP</span> - will be redirected towards the liquidity pool to sustain the price
<br /><br />
<span style={{color: "#f7b126" }}>0x000000000000000000000000000000000000dead</span> - means it is a holder and that it will absorb a part of the reflection every time a transaction is made. This will add a form of deflationary function to the token.
</p>
          <hr />
        </div>
        
        <div className="d-flex w-100 flex-center">
                        <div className="d-flex w-100 flex-center flex-column">
                        <PieChart className="max-h-180px w-100"
                        data={[
                            { title: '5% added as a liquidity pair on Pancake Swap', value: 50, color: '#2f2f2f' },
                            { title: '5% redistributed to all existing holders', value: 50, color: '#E38627' },
                        ]}
                        />
                        </div>
                    <div className="w-100 d-flex flex-column justify-content-around mt-4 align-items-center">
                        <h2 className="mb-2 text-white">How 10% fee is split</h2>
                        <div className="d-flex w-100">
                        <div className="bgtd1 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                            <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">5%</span>
                            <span className="text-center">redistributed to all existing holders</span>
                        </div>    
                        <div className="bgtd3 card card-bg-3 p-4 m-2 d-flex flex-column w-100">
                            <span className="text-orange font-size-h4 text-uppercase text-center font-weight-bolder">5%</span>
                            <span className="text-center">added as a liquidity pair on Pancake Swap</span>
                        </div>    
                        </div>
                    </div>
                    </div>
                    
      </div><hr />
      </div>
      </>
    )
}

export default Tokenomics
