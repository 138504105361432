import React, { useState } from "react";

import propTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  makeStyles,
  Slider,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Input } from "./styles";
import { useCallback } from "react";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { useEffect } from "react";
import { useWallet } from "../../store/wallet";
import { useContracts } from "../../store/contracts";
import constants from "../../config/constants";
import { SweetAlertNoButtons } from "../../utils/SweetAlert/SweetAlert";

const useStyles = makeStyles((theme) => ({
  parent: {
    zIndex: "9999 !important",
    "& .MuiPaper-root": {
      backgroundColor: "var(--color-bg-3)",
      color: "white",
      "@media (min-width: 768px)": {
        minWidth: "500px",
      },
      "@media (max-width: 768px)": {
        minWidth: "-webkit-fill-available",
      },
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "4px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButtonBase-root": {
      position: "relative !important",
      top: "inherit !important",
      right: "inherit !important",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h2 style={{ marginTop: "10px", color: "white" }}>{children}</h2>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function LegacyFarm({ open, onClose, onUpdate }) {
  const classes = useStyles();
  const { address } = useWallet();
  const { sphynxToken, bnbLpToken, bnbLegacyFarm } = useContracts();

  /**
   * States
   */
  const [depositing, setDepositing] = useState(false);
  const [withdrawing, setWithdrawing] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [lpBalance, setLpBalance] = useState(new BigNumber(0));
  const [pending, setPending] = useState(new BigNumber(0));
  const [depositedAmount, setDepositedAmount] = useState(new BigNumber(0));
  const [depositSlider, setDepositSlider] = useState(0);
  const [withdrawSlider, setWithdrawSlider] = useState(0);

  /**
   * Helpers
   */
  const depositValue = useMemo(() => {
    return new BigNumber(depositSlider / 100).multipliedBy(lpBalance);
  }, [depositSlider, lpBalance]);

  const displayDepositValue = useMemo(() => {
    return new BigNumber(depositSlider / 100)
      .multipliedBy(lpBalance)
      .multipliedBy(1e-18)
      .toFixed(4);
  }, [depositSlider, lpBalance]);

  const withdrawValue = useMemo(() => {
    return new BigNumber(withdrawSlider / 100).multipliedBy(depositedAmount);
  }, [withdrawSlider, depositedAmount]);

  const displayWithdrawValue = useMemo(() => {
    return new BigNumber(withdrawSlider / 100)
      .multipliedBy(depositedAmount)
      .multipliedBy(1e-18)
      .toFixed(4);
  }, [withdrawSlider, depositedAmount]);

  const displayPendingValue = useMemo(() => {
    return new BigNumber(pending).multipliedBy(1e-18).toFixed(4);
  }, [pending]);

  /**
   * Callbacks
   */
  const handleDepositSliderChange = useCallback((value) => {
    setDepositSlider(value);
  }, []);

  const handleWithdrawSliderChange = useCallback((value) => {
    setWithdrawSlider(value);
  }, []);

  const fetchData = useCallback(async () => {
    if (
      address &&
      bnbLpToken?.methods &&
      sphynxToken?.methods &&
      bnbLegacyFarm?.methods
    ) {
      const results = await Promise.all([
        bnbLpToken.methods.balanceOf(address).call(),
        bnbLegacyFarm.methods.deposited(0, address).call(),
        // bnbLegacyFarm.methods.pending(0, address).call(),
      ]);

      const [_lpBalance, _depositedAmount, _pending] = results;

      setLpBalance(new BigNumber(_lpBalance));
      setDepositedAmount(new BigNumber(_depositedAmount));
      // setPending(new BigNumber(_pending));

      if (onUpdate) {
        onUpdate();
      }
    }
  }, [address, bnbLegacyFarm, bnbLpToken, sphynxToken, onUpdate]);

  const handleDeposit = useCallback(async () => {
    if (!lpBalance.isGreaterThan(0)) {
      return;
    }

    try {
      setDepositing(true);

      const allowance = await bnbLpToken.methods
        .allowance(address, constants.farmAddress)
        .call();

      if (!new BigNumber(allowance).isGreaterThanOrEqualTo(depositValue)) {
        await bnbLpToken.methods
          .approve(constants.farmAddress, "9".repeat(64))
          .send({
            from: address,
          });

        SweetAlertNoButtons(
          "Success",
          "Approved your LP token spending. Now proceed with deposit."
        );
      }

      const success = await bnbLegacyFarm.methods
        .deposit(0, depositValue.toFixed(0))
        .send({
          from: address,
        });

      if (success) {
        SweetAlertNoButtons(
          "Success",
          "You have deposited your LP tokens and now is part of the farm."
        );
      }

      fetchData();
    } catch (error) {
      console.error(error);
      SweetAlertNoButtons("Error", error.message);
    } finally {
      setDepositing(false);
    }
  }, [address, bnbLpToken, bnbLegacyFarm, depositValue, lpBalance, fetchData]);

  const handleWithdraw = useCallback(async () => {
    if (!withdrawValue.isGreaterThan(0)) {
      return;
    }

    try {
      setWithdrawing(true);

      // const amount = depositedAmount.toFixed(0);

      // const success = await bnbLegacyFarm.methods.withdraw(0, amount).send({
      //   from: address,
      // });

      const success = await bnbLegacyFarm.methods.emergencyWithdraw(0).send({
        from: address,
      });

      if (success) {
        SweetAlertNoButtons(
          "Success",
          "You have withdrawed your LP tokens and claimed your rewards."
        );
      }

      fetchData();
    } catch (error) {
      console.error(error);
      SweetAlertNoButtons("Error", error.message);
    } finally {
      setWithdrawing(false);
    }
  }, [
    address,
    bnbLegacyFarm?.methods,
    depositedAmount,
    fetchData,
    withdrawValue,
  ]);

  const handleClaimRewards = useCallback(async () => {
    if (!pending.isGreaterThan(0)) {
      return;
    }

    try {
      setClaiming(true);

      const success = await bnbLegacyFarm.methods.withdraw(0, "0").send({
        from: address,
      });

      if (success) {
        SweetAlertNoButtons("Success", "You have claimed your rewards.");
      }

      fetchData();
    } catch (error) {
      console.error(error);
      SweetAlertNoButtons("Error", error.message);
    } finally {
      setClaiming(false);
    }
  }, [address, bnbLegacyFarm, fetchData, pending]);

  /**
   * Effects
   */
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      className={classes.parent}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <strong>BNB-SPH Farm (withdrawal only)</strong>
      </DialogTitle>
      <DialogContent>
        {/* Deposit */}
        <div style={{ marginBottom: "20px" }}>
          <Input
            label="Amount to farm (LP token)"
            defaultValue={0}
            variant="filled"
            type="number"
            fullWidth
            disabled
            value={displayDepositValue}
          />
          <div style={{ width: "97%", margin: "10px auto" }}>
            <Slider
              onChange={(_, v) => handleDepositSliderChange(v)}
              step={10}
              defaultValue={0}
              style={{ color: "#f7b125" }}
            />
          </div>
          <Button
            style={{ fontWeight: "bold", backgroundColor: "#f7b125" }}
            variant="contained"
            fullWidth
            onClick={handleDeposit}
            disabled={depositing || depositValue.isZero()}
          >
            Deposit
          </Button>
        </div>

        {/* Withdraw */}
        <div style={{ marginBottom: "20px" }}>
          <Input
            label="Amount to withdraw (LP token)"
            defaultValue={0}
            variant="filled"
            type="number"
            fullWidth
            disabled
            value={displayWithdrawValue}
          />
          <div style={{ width: "97%", margin: "10px auto" }}>
            <Slider
              step={10}
              defaultValue={0}
              style={{ color: "#754b92" }}
              onChange={(_, v) => handleWithdrawSliderChange(v)}
            />
          </div>
          <Button
            style={{
              fontWeight: "bold",
              backgroundColor: "#754b92",
            }}
            variant="contained"
            fullWidth
            onClick={handleWithdraw}
            disabled={withdrawing || withdrawValue.isZero()}
          >
            Withdraw
          </Button>
        </div>

        {/* Claim rewards */}
        <div>
          <Input
            label="Total rewards (SPH)"
            defaultValue={0}
            variant="filled"
            type="number"
            fullWidth
            disabled
            style={{ marginBottom: "10px" }}
            value={displayPendingValue}
          />
          <Button
            style={{
              fontWeight: "bold",
              backgroundColor: "#2dcf89",
            }}
            variant="contained"
            fullWidth
            onClick={handleClaimRewards}
            disabled={claiming || pending.isZero()}
          >
            Claim rewards
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

LegacyFarm.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func.isRequired,
  onUpdate: propTypes.func,
};

export default LegacyFarm;
