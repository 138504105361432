import React, { createContext, useCallback, useContext, useMemo } from "react";
import erc20abi from "erc-20-abi";

import FarmContract from "../abi/SphynxFarm.json";
import PancakeRouterABI from "../abi/PancakeRouterV2.json";

import { useWallet } from "./wallet";
import constants from "../config/constants";

const ContractsContext = createContext({
  sphynxToken: {},
  bnbFarm: {},
  bnbLegacyFarm: {},
  bnbLpToken: {},
  pancakeRouter: {},
});

export const ContractsProvider = ({ children }) => {
  const { web3, isWeb3Enabled } = useWallet();

  const loadContract = useCallback(
    (abi, address) => {
      if (web3.eth) {
        const contract = new web3.eth.Contract(abi, address);
        return contract;
      }
      return undefined;
    },
    [web3.eth]
  );

  const sphynxToken = useMemo(() => {
    if (isWeb3Enabled) {
      return loadContract(erc20abi, constants.sphynxTokenAddress);
    }
    return undefined;
  }, [loadContract, isWeb3Enabled]);

  const bnbFarm = useMemo(() => {
    if (isWeb3Enabled) {
      return loadContract(FarmContract.abi, constants.farmAddress);
    }
    return undefined;
  }, [isWeb3Enabled, loadContract]);

  const bnbLegacyFarm = useMemo(() => {
    if (isWeb3Enabled) {
      return loadContract(FarmContract.abi, constants.legacyFarmAddress);
    }
    return undefined;
  }, [isWeb3Enabled, loadContract]);

  const bnbLpToken = useMemo(() => {
    if (isWeb3Enabled) {
      return loadContract(erc20abi, constants.sphBnbLpAddress);
    }
    return undefined;
  }, [isWeb3Enabled, loadContract]);

  const pancakeRouter = useMemo(() => {
    if (isWeb3Enabled) {
      return loadContract(PancakeRouterABI, constants.pancakeRouterAddress);
    }
    return undefined;
  }, [isWeb3Enabled, loadContract]);

  const erc20 = useMemo(() => {
    return (address) => {
      if (isWeb3Enabled) {
        return loadContract(erc20abi, address);
      }
      return undefined;
    };
  }, [isWeb3Enabled, loadContract]);

  return (
    <ContractsContext.Provider
      value={{
        sphynxToken,
        bnbFarm,
        bnbLegacyFarm,
        bnbLpToken,
        loadContract,
        erc20,
        pancakeRouter,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export const useContracts = () => {
  const context = useContext(ContractsContext);

  if (!context) {
    throw new Error(
      "useContracts must be used within an ContractsContext Provider"
    );
  }

  return context;
};
