const constants = {
  sphynxTokenAddress: "0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00",
  legacyFarmAddress: "0xd51ac7a91d40076bd527604ba5ba998847e61e53",
  farmAddress: "0x5e0bb600e3109e784ed1d19dd572b7b90b401cef",
  sphBnbLpAddress: "0xa83FC67dc49e846F21FdA57572F09a32a757Aa5f",
  pancakeRouterAddress: "0x10ed43c718714eb63d5aa57b78b54704e256024e",
  bnbAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
};

export default constants;
