import React, { useEffect } from "react";

// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
const TeamFigure = ({ p1, p2, name, position, desc, linkedIn }) => {
  return (
    <div className="d-flex flex-column">
      <figure class="cd-image-container float-r-lg is-visible">
        <img height="320px" src={p1} alt="Modified Image" />

        <span class="cd-image-label" data-type="original"></span>
        <div class="cd-resize-img">
          <img height="320px" src={p2} alt="Original Image" />
          <span class="cd-image-label" data-type="modified"></span>
        </div>
        <span
          class="cd-handle"
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title="Drag me"
        ></span>
      </figure>
      <div className="d-flex flex-column align-items-center text-left mt-2 p-2 mlmobile27">
        <p className="w-100 mb-0 h3 text-white">
          {name}{" "}
          <a
            href={linkedIn}
            class="mx-2 text-gray"
            target="_blank"
            data-toggle="tooltip"
            title="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </p>
        <span className="w-100 text-uppercase">{position}</span>
        <p style={{ height: 170 }}>{desc}</p>
      </div>
    </div>
  );
};

export default TeamFigure;
