/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import { useDencrypt } from "use-dencrypt-effect";
import { SweetAlertTest } from "../../utils/SweetAlert/SweetAlert";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";

import "./vaults.css";
import LegacyFarm from "../../components/Farm/LegacyFarm";
import Farm from "../../components/Farm/Farm";
import { useWallet } from "../../store/wallet";
import { useContracts } from "../../store/contracts";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import constants from "../../config/constants";

const tokenSymbol = "SPH";
const tokenDecimals = 18;
const tokenAddress = "0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00";
const tokenImage = "https://i.imgur.com/2EwEfdf.png";

const values = ["Select a material"];

const Vaults = () => {
  const { result, dencrypt } = useDencrypt();

  const { address } = useWallet();
  const { bnbFarm, bnbLegacyFarm, pancakeRouter } = useContracts();

  const [bnbFarmStaked, setBnbFarmStaked] = useState(new BigNumber(0));
  const [legacyFarmModal, setLegacyFarmModal] = useState(false);
  const [farmModal, setFarmModal] = useState(false);
  const [bnbPrice, setBnbPrice] = useState(new BigNumber(0));

  const displayBnbFarmStaked = useMemo(() => {
    return bnbFarmStaked.multipliedBy(1e-18).toFixed(4);
  }, [bnbFarmStaked]);

  const displayBnbPrice = useMemo(() => {
    return bnbPrice.multipliedBy(1e-18).toFixed(12);
  }, [bnbPrice]);

  const fetchData = useCallback(async () => {
    if (
      address &&
      bnbFarm?.methods &&
      bnbLegacyFarm?.methods &&
      pancakeRouter?.methods
    ) {
      const _legacyStaked = await bnbLegacyFarm.methods
        .deposited(0, address)
        .call();
      const _staked = await bnbFarm.methods.deposited(0, address).call();
      setBnbFarmStaked(
        new BigNumber(_staked).plus(new BigNumber(_legacyStaked))
      );

      const [, _bnbPrice] = await pancakeRouter.methods
        .getAmountsOut(new BigNumber(1e18).toString(), [
          constants.sphynxTokenAddress,
          constants.bnbAddress,
        ])
        .call();

      setBnbPrice(new BigNumber(_bnbPrice));
    }
  }, [address, bnbFarm, bnbLegacyFarm, pancakeRouter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    dencrypt(values[0]);
  }, [dencrypt]);

  const handleLegacyFarmClose = useCallback(() => {
    setLegacyFarmModal(false);
  }, []);

  const handleFarmClose = useCallback(() => {
    setFarmModal(false);
  }, []);

  return (
    <>
      <Navbar />
      <Farm open={farmModal} onClose={handleFarmClose} onUpdate={fetchData} />
      <LegacyFarm
        open={legacyFarmModal}
        onClose={handleLegacyFarmClose}
        onUpdate={fetchData}
      />
      <div className="container my-5">
        <div className="d-flex justify-content-between w-100 flex-vaults">
          <div className="d-flex w-100 flex-center flex-column">
            <div className="d-flex flex-column no-phone">
              <h1 className="text-white font-size-bigger">{result}</h1>
              <p className="font-size-h2">Earn SPH tokens staking your LPs</p>
            </div>
            <div className="d-flex flex-column no-mac mb-8">
              <h1 className="text-white font-size-big">{result}</h1>
              <p className="font-size-h4">Earn SPH tokens staking your LPs</p>
            </div>
            <div className="d-flex flex-column w-100 card card-bg-3 m-2 p-4">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="text-white mb-0 no-mac font-size-base">
                  Where to buy SPH
                </h2>
                <h2 className="text-white mb-0 no-phone">Where to buy SPH</h2>
                <div
                  onClick={async (event) => {
                    const provider = window.web3.currentProvider;
                    provider.sendAsync(
                      {
                        method: "metamask_watchAsset",
                        params: {
                          type: "ERC20",
                          options: {
                            address: tokenAddress,
                            symbol: tokenSymbol,
                            decimals: tokenDecimals,
                            image: tokenImage,
                          },
                        },
                        id: Math.round(Math.random() * 100000),
                      },
                      (err, added) => {
                        console.log("provider returned", err, added);
                        if (err || "error" in added) {
                          console.log(
                            "'There was a problem adding the token.'"
                          );
                          return;
                        }
                        console.log("Token added!");
                      }
                    );
                  }}
                  className="d-flex text-muted cursor-pointer"
                >
                  <span className="mr-2">
                    Add SPH to <span className="no-phone">MetaMask</span>
                    <span className="no-mac">MM</span>
                  </span>
                  <img
                    src="https://i.imgur.com/vwS5ZJZ.png"
                    width="20px"
                    height="20px"
                    alt="MetaMask"
                  />
                </div>
              </div>
              <a
                target="_blank"
                href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00"
                className="btn btn-primary cursor-pointer flex-center"
                rel="noreferrer"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-center">
                    <img
                      src="https://i.imgur.com/Ojtioy7.png"
                      width="24px"
                      height="24px"
                      className="mr-2"
                      alt="PancakeSwap"
                    />{" "}
                    <span className="font-size-base text-uppercase">
                      PancakeSwap
                    </span>
                  </div>
                  <FontAwesomeIcon
                    icon={faArrowCircleRight}
                    className="text-warning font-size-h1"
                  />
                </div>
              </a>
              <p className="mt-2">More soon...</p>
            </div>
          </div>
          <div className="d-flex w-100 flex-center flex-column">
            <img
              src="https://i.imgur.com/PnAKwU4.png"
              class="position-relative sc-jgrIVwv DBTfNv"
              alt="Cat"
            />
            <h1 class="sc-hUpaWb blbXGfv px-4">
              <span className="no-phone">SPH VAULTS</span>
              <span className="no-mac font-size-big">SPH VAULTS</span>
            </h1>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="d-flex flex-column w-100 card card-bg-3 py-4 my-2 shadow-box-orange">
              <div className="header-card d-flex flex-center flex-column">
                <div className="tokens d-flex">
                  <img
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                    className="lp-tokens"
                    width="50px"
                    alt="BNB"
                  />
                  <img
                    src="https://i.imgur.com/2EwEfdf.png"
                    className="lp-tokens mln-20px"
                    width="50px"
                    alt="SPH"
                  />
                </div>
                <p className="mt-2 text-muted">BNB-SPH</p>
                <div className="position-absolute" style={{ top: 5, right: 0 }}>
                  <Tooltip title="Add SPH to MetaMask">
                    <Button
                      onClick={async (event) => {
                        const provider = window.web3.currentProvider;
                        provider.sendAsync(
                          {
                            method: "metamask_watchAsset",
                            params: {
                              type: "ERC20",
                              options: {
                                address: tokenAddress,
                                symbol: tokenSymbol,
                                decimals: tokenDecimals,
                                image: tokenImage,
                              },
                            },
                            id: Math.round(Math.random() * 100000),
                          },
                          (err, added) => {
                            console.log("provider returned", err, added);
                            if (err || "error" in added) {
                              console.log(
                                "'There was a problem adding the token.'"
                              );
                              return;
                            }
                            console.log("Token added!");
                          }
                        );
                      }}
                    >
                      <img
                        src="https://i.imgur.com/vwS5ZJZ.png"
                        width="20px"
                        height="20px"
                        alt=""
                      />
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <hr className="my-4" style={{ borderColor: "#000" }} />
              <div className="header-card d-flex flex-center flex-column">
                <div className="info d-flex w-100 px-8">
                  <div className="w-100 d-flex flex-column justify-content-start text-left text-muted">
                    <span>APR</span>
                    <span>Total Staked</span>
                    <span>Price</span>
                  </div>
                  <div className="w-100 d-flex flex-column justify-content-end text-right">
                    <span>5000 SPH / 3s</span>
                    <span>{displayBnbFarmStaked} LP</span>
                    <span>{displayBnbPrice} BNB</span>
                  </div>
                </div>
              </div>
              <hr className="my-4" style={{ borderColor: "#000" }} />
              <div className="footer-card d-flex flex-center">
                <div className="info d-flex w-100 flex-column px-8">
                  <a
                    target="_blank"
                    href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00"
                    className="px-4 py-2 w-100 btn btn-primary cursor-pointer flex-center"
                    rel="noreferrer"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-center">
                        <span className="font-size-base">Buy Token</span>
                      </div>
                      <img
                        src="https://i.imgur.com/fiXoHch.png"
                        width="24px"
                        height="24px"
                        alt=""
                      />
                    </div>
                  </a>
                  <a
                    target="_blank"
                    href="https://exchange.pancakeswap.finance/#/add/BNB/0x8bAc6b4AF65C8c1967a0FBc27cd37FD6059daa00"
                    className="px-4 my-2 py-2 w-100 btn btn-primary cursor-pointer flex-center"
                    rel="noreferrer"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-center">
                        <span className="font-size-base">
                          Provide Liquidity
                        </span>
                      </div>
                      <img
                        src="https://i.imgur.com/fiXoHch.png"
                        width="24px"
                        height="24px"
                        alt=""
                      />
                    </div>
                  </a>
                  <a
                    onClick={() => setFarmModal(true)}
                    className="px-4 py-2 w-100 btn btn-outline-secondary cursor-pointer flex-center"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-center">
                        <span className="font-size-base">Stake BNB-SPH</span>
                      </div>
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        className="font-size-h1"
                      />
                    </div>
                  </a>
                  <a
                    onClick={() => setLegacyFarmModal(true)}
                    className="px-4 my-2 py-2 w-100 btn btn-outline-secondary cursor-pointer flex-center"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-center">
                        <span className="font-size-base">
                          Stake BNB-SPH (Legacy!)
                        </span>
                      </div>
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        className="font-size-h1"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="d-flex flex-column w-100 card card-bg-3 py-4 my-2">
              <div className="header-card d-flex flex-center flex-column">
                <div className="tokens d-flex">
                  <img
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
                    className="lp-tokens"
                    width="50px"
                    alt=""
                  />
                  <img
                    src="https://i.imgur.com/2EwEfdf.png"
                    className="lp-tokens mln-20px"
                    width="50px"
                    alt=""
                  />
                </div>
                <p className="mt-2 text-muted">BUSD-SPH</p>
                <div className="position-absolute" style={{ top: 0, right: 0 }}>
                  <div
                    className="position-absolute"
                    style={{ top: 5, right: 0 }}
                  >
                    <Tooltip title="Add SPH to MetaMask">
                      <Button
                        onClick={async (event) => {
                          const provider = window.web3.currentProvider;
                          provider.sendAsync(
                            {
                              method: "metamask_watchAsset",
                              params: {
                                type: "ERC20",
                                options: {
                                  address: tokenAddress,
                                  symbol: tokenSymbol,
                                  decimals: tokenDecimals,
                                  image: tokenImage,
                                },
                              },
                              id: Math.round(Math.random() * 100000),
                            },
                            (err, added) => {
                              console.log("provider returned", err, added);
                              if (err || "error" in added) {
                                console.log(
                                  "'There was a problem adding the token.'"
                                );
                                return;
                              }
                              console.log("Token added!");
                            }
                          );
                        }}
                      >
                        <img
                          src="https://i.imgur.com/vwS5ZJZ.png"
                          width="20px"
                          height="20px"
                          alt=""
                        />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <hr className="my-4" style={{ borderColor: "#000" }} />
              <div className="header-card d-flex flex-center flex-column">
                <div className="info d-flex w-100 px-8">
                  <div className="w-100 d-flex flex-column justify-content-start text-left text-muted">
                    <span>APR</span>
                    <span>Total Staked</span>
                    <span>Price</span>
                  </div>
                  <div className="w-100 d-flex flex-column justify-content-end text-right">
                    <span>0</span>
                    <span>0 LP</span>
                    <span>0 USD</span>
                  </div>
                </div>
              </div>
              <hr className="my-4" style={{ borderColor: "#000" }} />
              <div className="footer-card d-flex flex-center">
                <div className="info d-flex w-100 flex-column px-8">
                  <a
                    target="_blank"
                    href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00"
                    className="px-4 py-2 w-100 btn btn-primary cursor-pointer flex-center"
                    rel="noreferrer"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-center">
                        <span className="font-size-base">Buy Token</span>
                      </div>
                      <img
                        src="https://i.imgur.com/fiXoHch.png"
                        width="24px"
                        alt=""
                        height="24px"
                      />
                    </div>
                  </a>
                  <a
                    target="_blank"
                    href="https://exchange.pancakeswap.finance/#/add/BNB/0x8bAc6b4AF65C8c1967a0FBc27cd37FD6059daa00"
                    className="px-4 my-2 py-2 w-100 btn btn-primary cursor-pointer flex-center"
                    rel="noreferrer"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-center">
                        <span className="font-size-base">
                          Provide Liquidity
                        </span>
                      </div>
                      <img
                        src="https://i.imgur.com/fiXoHch.png"
                        width="24px"
                        height="24px"
                        alt=""
                      />
                    </div>
                  </a>
                  <a
                    href="#stake"
                    onClick={() =>
                      SweetAlertTest("errorTR", `Error: We are working on it!`)
                    }
                    className="px-4 py-2 w-100 btn btn-outline-secondary cursor-pointer flex-center"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-center">
                        <span className="font-size-base">Stake SPH</span>
                      </div>
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        className="font-size-h1"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vaults;
