import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        
        box-sizing: border-box;
        --color-main: ${(props) => props.theme.colors.main};
        --color-mainDark: ${(props) => props.theme.colors.mainDark};
        --color-mainLight: ${(props) => props.theme.colors.mainLight};
        --color-mainLighter: ${(props) => props.theme.colors.mainLighter};
        --color-text: ${(props) => props.theme.colors.textColor};
        --color-textButton: ${(props) => props.theme.colors.textButton};
        --color-border: ${(props) => props.theme.colors.border};
        --color-border2: ${(props) => props.theme.colors.border2};
        --color-border3: ${(props) => props.theme.colors.border3};
        --color-border4: ${(props) => props.theme.colors.border4};
        --color-borderR: ${(props) => props.theme.colors.borderR};
        --color-picks: ${(props) => props.theme.colors.picks};
        --color-dark75: ${(props) => props.theme.colors.dark75};
        --color-hover: ${(props) => props.theme.colors.hover};
        --color-hover2: ${(props) => props.theme.colors.hover2};
        --color-hover3: ${(props) => props.theme.colors.hover3};
        --color-score: ${(props) => props.theme.colors.score};
        --color-bell: ${(props) => props.theme.colors.bell};
        --color-hoverText: ${(props) => props.theme.colors.hoverText};
        --color-textSecondaryButton: ${(props) =>
          props.theme.colors.textSecondaryButton};
        --color-white: ${(props) => props.theme.colors.whiteColor};
        --color-blackAndWhite: ${(props) => props.theme.colors.blackAndWhite};
        --color-blackAndWhiteR: ${(props) => props.theme.colors.blackAndWhiteR};
        --color-cardHeader: ${(props) => props.theme.colors.cardHeader};
        --shadow: ${(props) => props.theme.colors.shadow};
        --backgrounds-bgMain: ${(props) => props.theme.backgrounds.bgMain};
        --backgrounds-bgHeader: ${(props) => props.theme.backgrounds.bgHeader};
        --backgrounds-homeHeader: ${(props) =>
          props.theme.backgrounds.bgCardHome};
        --ttbg: ${(props) => props.theme.backgrounds.ttbg};
        --buttons-buttonMain: ${(props) => props.theme.buttons.buttonMain};
        --color-background-1: ${(props) => props.theme.colors.background1};
        --color-background-2: ${(props) => props.theme.colors.background2};
        --color-background-3: ${(props) => props.theme.colors.background3};
        --color-background-4: ${(props) => props.theme.colors.background4};
        --color-background-5: ${(props) => props.theme.colors.background5};
        --color-background-6: ${(props) => props.theme.colors.background6};
        --color-background-7: ${(props) => props.theme.colors.background7};
        --color-background-8: ${(props) => props.theme.colors.background8};
        --color-background-9: ${(props) => props.theme.colors.background9};
        --color-background-10: ${(props) => props.theme.colors.background10};
        --color-background-11: ${(props) => props.theme.colors.background11};
        --color-background-12: ${(props) => props.theme.colors.background12};
        --color-background-13: ${(props) => props.theme.colors.background13};
        --color-background-14: ${(props) => props.theme.colors.background14};
        --color-background-15: ${(props) => props.theme.colors.background15};
        --color-background-16: ${(props) => props.theme.colors.background16};
        --color-background-17: ${(props) => props.theme.colors.background17};
        --color-bg-1: ${(props) => props.theme.colors.bg1};
        --color-bg-2: ${(props) => props.theme.colors.bg2};
        --color-bg-3: ${(props) => props.theme.colors.bg3};
        --color-bg-4: ${(props) => props.theme.colors.bg4};
        @media ${(props) => props.theme.mediaQueries.small} {
            font-size:90%
        }
        @media ${(props) => props.theme.mediaQueries.smallest} {
            font-size:80%
        }
    }
    body {
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.6;
        background-color: #1f232b;
        color: var(--color-text);
    }
    a, input, textarea, button {
        outline: none;
        text-decoration: none;
        font-family: inherit;
        color: inherit;
    }
    a:hover, a:active { 
        outline: none;
        text-decoration: none;
    }
    p {
        font-size: 12px;
    }
    table {
    border-collapse: collapse;
    border-spacing: 0;
}
`;
