import React, { useState, useRef } from 'react'
import Navbar from "../../components/Navigation/Navbar/Navbarnou";
import { useDencrypt } from "use-dencrypt-effect";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import "./howtobuy.css";
import { SweetAlertTest } from "../../utils/SweetAlert/SweetAlert";
// FONTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faVideo } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      "& .MuiPaper-root" : {
          background: "var(--color-bg-3)",
          color: "white"
      }
    },
    heading: {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightRegular,
    },
    p: {
        fontSize: 16
    }
  }));
const values = ["Coming Soon"];
const HowToBuy = () => {
    const textAreaRef = useRef(null);
    const classes = useStyles();
    const { result, dencrypt } = useDencrypt();
    React.useEffect(() => {
        
          dencrypt(values[0]);
    
    
      }, []);
      function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        SweetAlertTest("successTR", `PancakeSwap Link copied !`)
      };
    return (
      <>
      <Navbar />
      
      <div className="container my-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-white mb-0">Sphynx Network - How to Buy</h1>
          <p class="text-white-50 fs-1rem">A step by step guide on how to buy Sphynx Network from Pancakeswap.</p>
          <hr />
        </div>
      </div>
      <div class="row text-light align-items-center">
   <div class="col-12 col-lg-12">
   <div className={` ${classes.root}  shadow-box-orange`}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: "white"}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <div className="d-flex flex-center">
            <FontAwesomeIcon icon={faVideo} className="text-muted font-size-bigger mr-4"/>
            <div className="d-flex flex-column">
        <Typography className={classes.heading}>Video Guide</Typography>
        <Typography className={classes.p}>Click here if you would prefer a video guide instead!</Typography>
            </div>
            </div>
        </AccordionSummary>
        <AccordionDetails>
        <div class="card-body">
        <div style={{width:"100%",height:0,position:"relative",paddingBottom:"56.250%"}}><iframe src="https://streamable.com/e/up6lr2" frameborder="0" width="100%" height="100%" allowfullscreen style={{width:"100%",height:"100%",position:"absolute",left:0,top:0,overflow:"hidden"}}></iframe></div>
               </div>
        </AccordionDetails>
      </Accordion>
    </div>
   </div>
</div>
<div className="row my-5 text-light flex-lg-row">
    <div className="col-12 col-lg-12">
    <h4 class="text-warning">Step 1: Connect to PancakeSwap Exchange</h4>
    <p className="font-size-lg">Open your wallet, now open the browser and paste the <b>PancakeSwap</b> trusted link from Sphynx Network telegram group or website.</p>
    <div class="input-group mb-2 shadow-none mt-2">
    <div class="input-group-prepend">
        <div class="input-group-text">SPH PancakeSwap LINK</div>
    </div>
    <input type="text" ref={textAreaRef} value="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00" class="form-control border-0" id="copyField" readOnly />
    <div class="input-group-append"><button onClick={copyToClipboard} id="copy" class="btn btn-success"><FontAwesomeIcon icon={faCopy} className="mr-2" /> Copy</button></div>
    
    </div>
    <p className="text-danger">For MetaMask, you need to make sure you are running on Binance Smart Chain (BSC) network before opening the browser!</p>
    <hr />
    <h4 class="text-warning mt-6">Step 2: Import Sphynx Network Token</h4>
    <p className="font-size-lg">You should be on <b>PancakeSwap</b> at this point, now tick "I understand" box and import Sphynx Network token, but <i>make sure you are connected to Binance Smart Chain (BSC)</i> </p>
    <hr />
    <h4 class="text-warning mt-6">Step 3: Set Slippage tollerance</h4>
    <p className="font-size-lg">Set the slippage tollerance to <b>12%</b> and exit the window, it will save it automatically</p>
    <p className="font-size-h4">Now set the amount of BNB you want to buy and click swap</p>
    <hr />
    <h4 class="text-warning mt-6">Congratulations! </h4>
    <p className="font-size-lg">You are now a Sphynx Network holder, make sure you custom add the token in your wallet, reach out admins on <a href="https://t.me/sphynxnetwork" className="text-info" >telegram</a> for further information.</p>
    <h4 className="text-white mt-2">Now all that's left is to <span className="text-danger">HODL!</span></h4>
    <hr />
    </div>
</div>
      </div>
      </>
    )
}

export default HowToBuy
