import React, { useEffect, useState } from "react";
import "../../utils/style.css";
import Header from "../../components/Header/Header";
// Animate on scroll
import ScrollAnimation from "@articulate/react-animate-on-scroll";
import Functions from "../../components/Functions/Functions";
import AboveFooter from "../../components/AboveFooter/AboveFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckSquare,
  faDrumstickBite,
  faFaucet,
  faGrinSquint,
  faGrinTongue,
  faHandHoldingUsd,
  faHeadSideCough,
  faKissWinkHeart,
  faMinus,
  faPaperPlane,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import CountUp from "react-countup";
import Audit from "../../components/Audit/Audit";

const Homenou = () => {
  const [holders, setHolders] = useState(0);
  const [tokenInfo, setTokenInfo] = useState();
  const [totalSupply, setTotalSupply] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fetchis, setFetchis] = useState(true);
  const [SPHInfo, setSPHInfo] = useState({});
  useEffect(() => {
    fetch(
      "https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00&page=1&offset=10000&apikey=R92XCMUBNAIXXTDYAU8MTHCRRIJEZVD9U9"
    )
      .then((response) => response.json())
      .then((data) => setHolders(data.result.length))
      .then((data) => setFetchis(true));
  }, []);
  useEffect(() => {
    fetch(
      "https://api.bscscan.com/api?module=token&action=tokeninfo&contractaddress=0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00&apikey=R92XCMUBNAIXXTDYAU8MTHCRRIJEZVD9U9"
    )
      .then((response) => response.json())
      .then((data) => setTokenInfo(data.result[0]));
  }, []);
  useEffect(() => {
    if (!loading && tokenInfo) {
      setTotalSupply(tokenInfo.totalSupply);
    }
  }, [loading]);
  useEffect(() => {
    if (fetchis === true) {
      setLoading(false);
    }
  }, [fetchis]);

  async function fetchSPHData() {
    await fetch(new Request("https://api.livecoinwatch.com/coins/single"), {
      method: "POST",
      headers: new Headers({
        "content-type": "application/json",
        "x-api-key": "14de80e1-d31c-4a84-b6f5-28c35520e11c",
      }),
      body: JSON.stringify({
        currency: "USD",
        code: "_SPH",
        meta: true,
      }),
    })
      .then((response) => response.json())
      .then((data) => setSPHInfo(data));
  }
  useEffect(() => {
    fetchSPHData();
  }, []);
  console.log(SPHInfo);
  console.log(SPHInfo?.rate?.toFixed(18));

  const totalSupplyFixed = parseFloat(tokenInfo?.totalSupply).toFixed(0);
  return (
    <>
      <Header />
      <div class="d-flex flex-column flex-center w-100 mt-50px">
        <div className="container">
          <div class="d-flex text-lg-center flex-column flex-lg-row header-stats text-center w-100">
            <div class="flex-fill w-100 py-3">
              <p class="mb-0 h3 text-white">Market Cap</p>
              {/* <p class="mb-0 text-light small"><NumberFormat value={1000000000000} displayType={'text'} thousandSeparator={true} suffix={" SPH"} /></p> */}
              <p class="mb-0 text-light small">coming soon</p>
            </div>
            <div class="flex-fill w-100 py-3">
              <p class="mb-0 h3 text-white">Current Price</p>
              <p class="mb-0 text-light small">
                <NumberFormat
                  value={SPHInfo?.rate?.toFixed(8)}
                  displayType={"text"}
                  decimals={0}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </p>
            </div>
            <div class="flex-fill w-100 py-3 d-none">
              <p class="mb-0 h3 text-white">Volume 24h</p>
              <p class="mb-0 text-light small">
                {loading ? (
                  "loading"
                ) : (
                  <NumberFormat
                    value={SPHInfo?.volume}
                    displayType={"text"}
                    decimals={0}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                )}
              </p>
            </div>
            <div class="flex-fill w-100 py-3 d-none">
              <p class="mb-0 h3 text-white">Holders</p>
              <p class="mb-0 text-light small font-weight-bolder">
                {loading ? (
                  "loading"
                ) : (
                  <CountUp
                    end={holders}
                    decimals={0}
                    useEasing={true}
                    duration={1}
                  />
                )}
              </p>
            </div>
            <div class="flex-fill w-100 py-3">
              <p class="mb-0 h3 text-white">Supply</p>
              <p class="mb-0 text-light small">
                {loading ? (
                  "loading"
                ) : (
                  <NumberFormat
                    value={SPHInfo?.totalSupply}
                    displayType={"text"}
                    decimals={0}
                    thousandSeparator={true}
                    suffix={" SPH"}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row-alt text-center">
        <div class="container-fluid">
          <div class=" medium-row align-items-lg-center">
            <div class="col-12">
              <a
                href="https://www.xt.com/tradePro/sph_usdt"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/Ck7BfeF.png" height="40" />
              </a>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/sphynxv2-b97c1.appspot.com/o/Sphynx_Network_SPH_Token_Smart_Contracts_Security_Audit_Report.pdf?alt=media&token=bdb1b0cf-e283-4b5b-beca-a3dfb3d88659"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/41AyS1t.png" height="40" />
              </a>
              <a
                href="https://latoken.com/exchange/SPHN_USDT"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/paFFGxU.png" height="40" />
              </a>
              <a
                href="https://nomics.com/assets/sph2-sphynx-network"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/OjPG9cn.png" height="40" />
              </a>
              <a
                href="https://app.1inch.io/#/56/swap/BNB/SPH"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/F899sS6.png" height="40" />
              </a>
              <a
                href="https://dex.guru/token/0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00-bsc"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/IIiPgNb.png" height="40" />
              </a>
              <a
                href="https://www.dextools.io/app/pancakeswap/pair-explorer/0xa83fc67dc49e846f21fda57572f09a32a757aa5f"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/kTBGC1u.png" height="40" />
              </a>
              <a
                href="https://coinmarketcap.com/currencies/sphynx-network/"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/jUwyLPd.png" height="40" />
              </a>
              <a
                href="https://www.coingecko.com/en/coins/sphynx-network"
                target="_blank"
                class="d-inline-block m-3"
              >
                <img src="https://i.imgur.com/m3qN3DR.png" height="40" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Functions />
      <div className="row-alt d-flex flex-column" id="roadmap">
        <div
          className="d-flex flex-column flex-center py-8"
          style={{ background: "#15181d" }}
        >
          <div className="mw1100 d-flex flex-center flex-column section-title">
            <h2>Roadmap</h2>
          </div>
        </div>
        <div className="py-8">
          <div className="mw1100 h-100 w-100 d-flex flex-center py-4">
            <section className="row justify-content-center mb-5 w-100">
              <div className="timeline col-md-10 col-md-offset-2 col-sm-12 col-xs-12">
                <div className="tl-container left">
                  <div className="date">Q1 2021</div>
                  <FontAwesomeIcon
                    icon={faGrinSquint}
                    className="text-warning icon no-phone"
                  />
                  <div className="content">
                    <h2 className="text-gray text-uppercase">
                      Geb God of the Earth
                    </h2>
                    <p>
                      Smart Contract Creation{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Website Creation{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Token burn{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Ownership Renounce{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Community build{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Presale start{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                  </div>
                </div>
                <div className="tl-container right">
                  <div className="date">Q2 2021</div>
                  <FontAwesomeIcon
                    icon={faGrinTongue}
                    className="text-warning icon no-phone"
                  />
                  <div className="content">
                    <h2 className="text-gray text-uppercase">
                      Nut Goddess of the Sky
                    </h2>
                    <p>
                      New Website & Design{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      New contract creation{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      New tokenomics{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Presale with Dxsale{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Liquidity Lock{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Funds Vested{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Presale End{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Pancakeswap listing{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Smart contract audit{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      1inch listing{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      CMC and CG listing{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                  </div>
                </div>
                <div className="tl-container left">
                  <div className="date">Q3-Q4 2021</div>
                  <FontAwesomeIcon
                    icon={faKissWinkHeart}
                    className="text-warning icon no-phone"
                  />
                  <div className="content">
                    <h2 className="text-gray text-uppercase">The Rise of Ra</h2>
                    <p>
                      Farming and NFT staking{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Sphynx vaults{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      Sphynx NFT vaults{" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                    <p>
                      LaToken Exchange listing{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                    <p>
                      XT.com Exchange listing{" "}
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success ml-2"
                      />
                    </p>
                  </div>
                </div>
                <div className="tl-container right">
                  <div className="date">Q1-Q2 2022</div>
                  <FontAwesomeIcon
                    icon={faKissWinkHeart}
                    className="text-warning icon no-phone"
                  />
                  <div className="content">
                    <h2 className="text-gray text-uppercase">
                      The Eye of Horus
                    </h2>
                    <p>
                      Governance token{" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                    <p>
                      New token launch (SPHX){" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                    <p>
                      NFT Marketplace{" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                    <p>
                      Fractional ownership{" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                  </div>
                </div>
                <div className="tl-container left">
                  <div className="date">Q3-Q4 2022</div>
                  <FontAwesomeIcon
                    icon={faKissWinkHeart}
                    className="text-warning icon no-phone"
                  />
                  <div className="content">
                    <h2 className="text-gray text-uppercase">
                      BASTET - THE CAT GODDESS
                    </h2>
                    <p>
                      Play to earn Game{" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                    <p>
                      Cards combat arena{" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                    <p>
                      Cross-chain NFT Portal{" "}
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning ml-2"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-center py-8"
        style={{ background: "#15181d" }}
      >
        <div className="mw1100 d-flex flex-center flex-column section-title">
          <h2>Team</h2>
          <p className="text-gray">Our Core Team Members</p>
        </div>
      </div>
      <AboveFooter />
    </>
  );
};

export default Homenou;
